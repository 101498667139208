import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyName",
    label: "Client Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "company_address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serviceReqNumber",
    label: "SRF Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "poNumber",
    label: "PO_Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "totalInstruments",
    label: "Total Instruments",
    align: "left",
    minWidth: 40,
  },
  {
    id: "completed",
    label: "Completed",
    align: "left",
    minWidth: 40,
  },
  {
    id: "pending",
    label: "Pending",
    align: "left",
    minWidth: 40,
  },
];

let notNeededColumn = ["id"];

const WcrReport = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [data, setFinalData] = React.useState([]);
  const [clientsArray, setClientsArray] = React.useState([]);
  const [companyId, setCompanyId] = React.useState(null);
  const [POArray, setPOArray] = React.useState([]);
  const [selectedPoNumber, setSelectedPoNumber] = React.useState(null);
  const [updatedCombinedArray, setUpdatedCombinedArray] = React.useState([]);
  const [searched, setSearched] = React.useState(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Client Name": "name",
        "GST Number": "gstNumber",
        "Occupier Name": "occupierName",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }



    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT srfs.serviceReqNumber, srfs.id, srfs.poNumber, srfs.poDate, srfs.address, srfs.clientId, clients.companyName, addresses.address AS company_address, (SELECT COUNT(*) FROM srfInstruments WHERE srfInstruments.srfsId = srfs.id) AS totalInstruments FROM srfs LEFT JOIN clients ON srfs.clientId = clients.id LEFT JOIN addresses ON srfs.address = addresses.id WHERE srfs.poNumber = '${selectedPoNumber}' AND srfs.clientId=${companyId}  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM srfs LEFT JOIN clients ON srfs.clientId = clients.id LEFT JOIN addresses ON srfs.address = addresses.id WHERE srfs.poNumber = '${selectedPoNumber}'  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const getClients =()=>{
    
    axiosWithToken
      .get(BASE_URL + `clients?_fields=id,companyName,address`)
      .then((res) => {
        setClientsArray(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const getPONambers =()=>{
    let query={
      "query":`SELECT poAcceptance.poDate,poAcceptance.totalInstrumentQuantiity, poAcceptance.poNumber,quotation.clientId, poAcceptance.quotationId, poAcceptance.id AS poId FROM poAcceptance LEFT JOIN quotation ON poAcceptance.quotationId = quotation.id WHERE quotation.clientId = ${companyId}`
    }
    axiosWithToken
      .post(BASE_URL + `dynamic`,query)
      .then((res) => {
        setPOArray(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }
  useEffect(() => {
   if(companyId) getPONambers();
  }, [companyId]);

  var refresh = () => {
    window.location.reload(false);
  };

  const getUpdatedCombinedArray = () => {
    // Calculate the total instrument quantity for the selected PO
    const poInstrumentQuantity = POArray.reduce((total, po) => 
      po.poNumber === selectedPoNumber ? total + po.totalInstrumentQuantiity : total, 0
    );
  
    // Reduce the data array into a result object
    const result = data.reduce((acc, curr) => {
      const key = `${curr.companyName}_${curr.company_address}`;
      if (!acc[key]) {
        acc[key] = { ...curr };
      } else {
        acc[key].totalInstruments += curr.totalInstruments;
        acc[key].serviceReqNumber += `, ${curr.serviceReqNumber}`;
      }
      return acc;
    }, {});
  
    // Transform the result object into an updated array with completed and pending fields
    const updatedCombinedArray = Object.values(result).map(item => ({
      ...item,
      completed: poInstrumentQuantity,
      pending: item.totalInstruments - poInstrumentQuantity
    }));
  
    // Set the updated combined array
    setUpdatedCombinedArray(updatedCombinedArray);
  };
  
  useEffect(() => {
    if (data.length > 0) getUpdatedCombinedArray();
  }, [POArray, data, selectedPoNumber]);
  
  const search = () => {
    setSearched(true);    
    fetchFinalData();
  };
  const clearSearch = () => {
    setSearched(false); 
    setUpdatedCombinedArray([]);
   };

  useEffect(() => {
   
    getClients()
  }, []);

  useEffect(() => {
    // if(selectedPoNumber) fetchFinalData();
    if(selectedPoNumber) getTotalRows();
  }, [rowsPerPage, page,selectedPoNumber]);

  const actions = [];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2, p:2 }}>
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
        <Grid
          container
          spacing={2}
          mb={6}
          justifyContent={"start"}
          alignItems={"center"}
        >
      <Grid item xs={12} sm={12} md={3} lg={3}>
  <Autocomplete
    size="small"
    id="client-autocomplete"
    options={clientsArray}
    getOptionLabel={(option) => option.companyName || ''}
    renderInput={(params) => <TextField {...params} label="Client" />}
    onChange={(event, value) => {
      if (value != null) {
        setCompanyId(value.id);
      } else {
        setCompanyId("");
      }
      setSearched(false); 
    }}
  />
</Grid>
<Grid item xs={12} sm={12} md={2} lg={2 }>
  <Autocomplete
    size="small"
    id="po-autocomplete"
    options={POArray}
    getOptionLabel={(option) => option.poNumber || ''}
    renderInput={(params) => <TextField {...params} label="PO Number" />}
    onChange={(event, value) => {
      if (value != null) {
        setSelectedPoNumber(value.poNumber);
      } else {
        setSelectedPoNumber(null);
      }
      setSearched(false); 
    }}
  />
</Grid>

          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
           {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          
            <div style={{ marginTop: "11px" }}>
              <ExcelDownload
                finalData={updatedCombinedArray}
                notNeededColumn={notNeededColumn}
              />
            </div>
        
        </Grid>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          columns={columns}
          finalData={updatedCombinedArray}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
}

export default WcrReport